<template>

  <div id="page-user-list">

    <div class="vx-card p-6" v-show="loaded">

      <!-- Header -->
      <div class="flex justify-between flex-wrap items-center">

        <!-- Search Form -->
        <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />

        <div style="display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 16px; align-items: center;">
          <v-select v-model="year" :clearable="false" :options="yearOptions"></v-select>
          <!-- Button Add -->
          <vs-button color="primary" icon-pack="feather" icon="icon-plus" class="mr-4" @click="addItem">Add Item</vs-button>
        </div>

      </div>


      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="itemsData"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <!-- Pagination -->
      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full">
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ itemsData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : itemsData.length }} of {{ itemsData.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>

              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <div class="vx-col sm:w-1/2 w-full">
          <!-- PAGINATION -->
          <vs-pagination
            :total="totalPages"
            :max="7"
            v-model="currentPage" />
        </div>
      </div>

      <!--  Popup  -->
      <vs-popup classContent="popup-example" :title="`${mode} item`" :active.sync="popup">

        <div class="vx-row mb-3">
          <div class="vx-col md:w-1/1 w-full">
            <label class="vs-input--label">{{$i18n.t('PODate')}}</label>
            <datepicker format="d/MM/yyyy" :value="form.tanggal_po" @input="form.tanggal_po = $moment($event)"></datepicker>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col md:w-1/1 w-full">
            <label class="vs-input--label">{{$i18n.t('DeliveryDate')}}</label>
            <datepicker format="d/MM/yyyy" :value="form.delivery_date" @input="form.delivery_date = $moment($event)"></datepicker>
          </div>
        </div>

        <div class="vx-row mb-3" v-if="!isFromForecast">
          <div class="vx-col md:w-1/1 w-full">
            <small class="mb-1">{{ $i18n.t('CustomerName') }}</small>
            <v-select :clearable="false" v-model="form.customer_name" :reduce="company => company.nama" :value="form.customer_name" label="nama" :options="companies"></v-select>
          </div>
        </div>

        <div class="vx-row mb-3" v-if="isFromForecast">
          <div class="vx-col md:w-1/1 w-full">
            <vs-input type="number" class="w-full mb-3" :label="$i18n.t('PONumber')" v-model="form.po_number" />
          </div>
        </div>

        <div class="vx-row mb-3" v-if="isFromForecast">
          <div class="vx-col md:w-1/1 w-full">
            <small class="mb-1">{{ $i18n.t('Forecast') }}</small>
            <v-select :clearable="false" v-model="form.id_forecast" :value="form.id_forecast" label="project_name" :options="forecasts"></v-select>
             <span class="text-danger text-sm"  v-show="errors.has('id_forecast')">{{ errors.first('id_forecast') }}</span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full">
            <small class="mb-1">{{ $i18n.t('PriceCurrency') }}</small>
            <v-select v-model="form.price_currency" :clearable="false" :options="['EUR', 'USD', 'Rp']"></v-select>
          </div>
          <div class="vx-col md:w-3/4 w-full">
            <vs-input
              v-currency
              class="w-full mb-3"
              :label="$i18n.t('PriceValue')"
              v-model="form.price_value"
              :value="form.price_value"
              @change="form.price_value = $event"
            />
          </div>
        </div>

        <div class="vx-col md:w-1/1 w-full">
          <vs-input :label="$i18n.t('Attachment')" type="file" @change="handleFileChange($event.target.name, $event.target.files)" name="file" class="w-full mt-4" />
        </div>

        <div class="mt-5">
          <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-save" @click="storeItem">
            {{ $i18n.t('Save') }}
          </vs-button>
        </div>

      </vs-popup>

    </div>
  </div>

</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import Datepicker from "vuejs-datepicker";
import { CurrencyDirective } from 'vue-currency-input';

import axios from '@/axios';

// Store Module
import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'

// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererVerified from './cell-renderer/CellRendererVerified.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import CellRendererFile from './cell-renderer/CellRendererFile.vue'
import moment from "moment";


export default {
  components: {
    AgGridVue,
    vSelect,
    Datepicker,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
    CellRendererFile
  },

  directives: {
    currency: CurrencyDirective
  },

  data () {
    return {
      loaded: false,
      searchQuery: '',
      year: '2021',
      yearOptions: [],
      form:{
        tanggal_po: '',
        delivery_date: '',
        price_currency: 'Rp'
      },
      defaultForm:{
        tanggal_po: this.$moment(new Date()),
        delivery_date: this.$moment(new Date()),
        price_currency: 'Rp'
      },

      forecasts: [],
      companies: [],

      popup: false,
      mode: '',
      // AgGrid
      gridApi: null,
      gridOptions: {},
      itemsData: [],
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t('PODate'),
          field: 'tanggal_po',
          filter: true,
          headerClass: 'text-center',
          cellClass: 'text-center'
        },
        {
          headerName: this.$i18n.t('PONumber'),
          field: 'po_number',
          filter: true,
        },
        {
          headerName: this.$i18n.t('Project'),
          field: 'projek',
          filter: true,
        },
        {
          headerName: this.$i18n.t('Price'),
          field: 'nilai',
          filter: true,
        },
        {
          headerName: 'Action',
          cellRendererFramework: 'CellRendererActions',
          headerClass: 'text-center',
          cellClass: 'text-center',
          cellRendererParams: {
            editItem: this.editItem.bind(this),
            deleteItem: this.deleteItem.bind(this)
          }
        }
      ],
    }
  },
  computed: {
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },
    isFromForecast(){
      return this.$route.params.tipe === 'from_forecast';
    }
  },
  methods: {
    setYears () {
      this.year = new Date().getFullYear();

      for(let year = 2015; year <= parseInt(this.year); year++){
        this.yearOptions.push(year.toString())
      }

      this.yearOptions.reverse()
    },
    loading () {
      this.loaded = false;

      this.$vs.loading({
        type: 'border',
        color: 'primary',
        text: `Wait a minute, It's getting data.`
      })
    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    getData () {
      let params = {
        tipe: this.$route.params.tipe,
        year: this.year,
      };

      axios.get('/sales/proses-order', {params}).then(resp => this.itemsData = resp.data ).catch(err => {
        this.$vs.notify({
          title:'Oops something error',
          text: err.data.message,
          color:'danger'
        })
      })
      .finally(() => {
        this.onLoaded();
      })
    },

    // handle file on change
    handleFileChange (event, files) {
      this.form.attachment = files[0];
    },

    // get all forecasts
    getForecasts(){
      axios.get('/sales/forecasts').then(({data}) => {
        this.forecasts = data
      } ).catch(err => {
        this.$vs.notify({
          title:'Oops something error',
          text: err.data.message,
          color:'danger'
        })
      })
    },

    // get customer companies
    getCompanies(){
      axios.get('/sales/company').then(({data}) => {
        this.companies = data
      } ).catch(err => {
        this.$vs.notify({
          title:'Oops something error',
          text: err.data.message,
          color:'danger'
        })
      })
    },

    addItem () {
      this.popup = true;
      this.mode = 'add';

      let date = moment(new Date()).format('DD');
      let month = moment(new Date()).format('MM');

      this.form.tanggal_po = this.year + '-' + month + '-' + date;
      this.form.delivery_date = this.year + '-' + month + '-' + date;
    },

    editItem(item){

      this.mode = 'edit';
      this.popup = true;
      this.itemSelected = item;

      if(this.isFromForecast){
        this.form = {
          tanggal_po: item.tanggal_po,
          po_number: item.po_number,
          id_forecast: this.forecasts.find(forecast => forecast.id === item.id_forecast),
          price_currency: item.nilai.split(' ')[0].replace('.', ''),
          price_value: item.nilai.split(' ')[1],
          delivery_date: item.delivery_date,
          id_user: this.$store.state.AppActiveUser.nik,
          tipe: this.$route.params.tipe,
        }
      }
      else{
        this.form = {
          customer_name: item.customer,
          tanggal_po: item.tanggal_po,
          price_currency: item.nilai.split(' ')[0].replace('.', ''),
          price_value: item.nilai.split(' ')[1],
          delivery_date: item.delivery_date,
          id_user: this.$store.state.AppActiveUser.nik,
          tipe: this.$route.params.tipe,
        }
      }
    },

    storeItem () {
      if (this.mode == 'add') {
        let body = {}
        if(this.isFromForecast){
          body = {
            tanggal_po: this.form.tanggal_po,
            po_number: this.form.po_number,

            id_forecast: this.form.id_forecast == undefined ? '' : this.form.id_forecast.id,
            projek: this.form.id_forecast == undefined ? '' : this.form.id_forecast.project_name,

            price_currency: this.form.price_currency,
            price_value: this.form.price_value,
            delivery_date: this.form.delivery_date,
            id_user: this.$store.state.AppActiveUser.nik,
            tipe: this.$route.params.tipe,
          };
        }
        else{
          body = {
            customer_name: this.form.customer_name,
            tanggal_po: this.form.tanggal_po,
            price_currency: this.form.price_currency,
            price_value: this.form.price_value,
            delivery_date: this.form.delivery_date,
            id_user: this.$store.state.AppActiveUser.nik,
            tipe: this.$route.params.tipe,
          };
        }

        axios.post('/sales/proses-order', body).then(resp => {
          this.popup = false;
          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.getData();
            this.mode = false;
          });
        }).catch(err => {
          if(err.data.errors) {
            this.$setErrorsFromResponse(err.data.errors);
          } else {
            this.$catchErrorResponse(err)
          }
          setTimeout(() => {
              this.$validator.errors.clear();
          }, 3000);

          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }

      else if (this.mode == 'edit') {
        let body = {};

        if(this.isFromForecast){
          body = {
            tanggal_po: this.form.tanggal_po,
            po_number: this.form.po_number,
            id_forecast: this.form.id_forecast.id,
            projek: this.form.id_forecast.project_name,
            price_currency: this.form.price_currency,
            price_value: this.form.price_value,
            delivery_date: this.form.delivery_date,
            id_user: this.$store.state.AppActiveUser.nik,
            tipe: this.$route.params.tipe,
            _method: 'PUT'
          }
        }
        else{
          body = {
            customer_name: this.form.customer_name,
            tanggal_po: this.form.tanggal_po,
            price_currency: this.form.price_currency,
            price_value: this.form.price_value,
            delivery_date: this.form.delivery_date,
            id_user: this.$store.state.AppActiveUser.nik,
            tipe: this.$route.params.tipe,
            _method: 'PUT'
          }
        }

        axios.post('/sales/proses-order/' + this.itemSelected.no_po, body).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.getData();
            this.mode = false;
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }
    },

    deleteItem(item) {
      axios.delete('/sales/proses-order/' + item.no_po).then(resp => {
        this.$swal({
          title: 'Successful',
          text: resp.data.message,
          icon: 'success'
        }).then(() => {
          this.getData();
        });
      }).catch(err => {
        this.$vs.notify({
          title: 'Opps something error',
          text: err.data.message,
          color: 'danger'
        })
      })
    },

    onLoaded(){
      this.loaded = true;
      this.$vs.loading.close();
    },

    showToast(content){
      this.$bvToast.toast(content, {
        title: `Notification`,
        toaster: 'b-toaster-top-right',
        variant: 'info',
        solid: true
      })
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }

    this.gridApi.sizeColumnsToFit();
  },
  watch: {
    year () {
      this.getData();
    },
    $route () {
      this.getData();
    },

    popup(state){
      if(state === false){
        this.form = this.defaultForm
      }
    },

    form: {
      deep: true,
      handler () {
        let year = new Date(this.form.tanggal_po).getFullYear();
        axios.get('/sales/forecasts/' + year).then(({data}) => {
          this.forecasts = data.values
          console.log(this.forecasts)
        } ).catch(err => {
          this.$vs.notify({
            title:'Oops something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }
    }
  },
  created () {
    this.setYears();
    this.loading();
    this.getData();
    this.getForecasts();
    this.getCompanies();
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
